/* Reset all styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Set default font family and size */
body {
  font-family: Arial, sans-serif;
  font-size: 16px;
}

/* Remove list styles */
ul, ol {
  list-style: none;
}

/* Remove hyperlink styles */
a {
  text-decoration: none;
  color: inherit;
}

/* Remove form element styles */
button, input, select, textarea {
  font-family: inherit;
  font-size: inherit;
  border: none;
  outline: none;
  background: none;
}

/* Set default box-sizing */
html {
  box-sizing: border-box;
}

/* Set default margin and padding for all elements */
body, html {
  margin: 0;
  padding: 0;
}
