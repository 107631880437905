@media (any-pointer: fine) {
	.cursor {
		position: fixed;
		top: 0;
		left: 0;
		display: block;
		pointer-events: none;
		z-index: 5000;
	}

	.cursor__inner {
		/* fill: var(--cursor-fill);
		stroke: var(--cursor-stroke);
		stroke-width: var(--cursor-stroke-width);
		opacity: 0.7; */
	}

	.no-js .cursor {
		display: none;
	}

}

@media (any-pointer: coarse) {
	.cursor {
		display: none;
	}
}