:root {
  --main-theme-color: #F79B79;
  --second-theme-color: #ED3D65;
  --third-theme-color: #FCD6C8;
}

@font-face {
  font-family: 'Montrelo';
  src: url('/src/assets/Montrelo.woff') format('woff');
  /*src: url('path/to/your/font.woff') format('woff'),
       url('path/to/your/font.woff2') format('woff2'),
       url('path/to/your/font.ttf') format('truetype');*/
  /* Add more formats if needed */
}

.App {
  color: var(--main-theme-color);
  font-family: Montrelo;
  height: 100vh;
  overflow: hidden;
  /* padding: 4em 6em 0 6em; */
}

.App.intro-in {
  /* height: auto; */
  height: max-content;
  /* overflow: visible; */
}


/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App h1, .App h2 {
  font-weight: 400;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
