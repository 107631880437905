#preload {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: var(--third-theme-color);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: all 1s cubic-bezier(0.8, 0, 0.2, 1);
    opacity: 1;
    overflow: hidden;
    translate: none;
    rotate: none;
    scale: none;
    translate: none;
    rotate: none;
    scale: none;
    transform: translate(0%, 0) translate3d(0px, 0px, 0px);
}


#preload.slide {
    transform: translate(0%, -100%) translate3d(0px, 0px, 0px);
}

#preload figure {
    transform: translate(0%, 0%) translate3d(0px, 0px, 0px);
    transition: transform 1s cubic-bezier(0.8, 0, 0.2, 1);
    overflow: hidden;
}

#preload figure img {
    transform: translate(0%, 0%) translate3d(0px, 0px, 0px);
    transition: transform 1.2s cubic-bezier(0.8, 0, 0.2, 1);
    display: block;
    transition-delay: 130ms;
}

#preload.slide figure img {
    transform: translate(0%, -1000%) translate3d(0px, 0px, 0px);
}

#preload.slide figure {
    transform: translate(0%, 900%) translate3d(0px, 0px, 0px);
}

#preload .preload_shapes#preload_shape1 {
    width: clamp(300px, 25%, 480px);
    top: 0;
    right: 0;
    transform: translate(-5%, 5%) translate3d(0px, 0px, 0px);
}

#preload.slide .preload_shapes#preload_shape1 {
    transform: translate(0%, 600%) translate3d(0px, 0px, 0px);
}

#preload .preload_shapes#preload_shape2 {
    width: clamp(348px, 29%, 557px);
    top: 0;
    left: 0;
    transform: translate(-3%, 63%) translate3d(0px, 0px, 0px);
}

#preload.slide .preload_shapes#preload_shape2 {
    transform: translate(0%, 400%) translate3d(0px, 0px, 0px);
}

#preload .preload_shapes#preload_shape3 {
    width: clamp(228px, 19%, 365px);
    top: 0;
    right: 0;
    transform: translate(4%, 152%) translate3d(0px, 0px, 0px);
}

#preload.slide .preload_shapes#preload_shape3 {
    transform: translate(0%, 800%) translate3d(0px, 0px, 0px);
}

#preload .preload_shapes#preload_shape4 {
    width: clamp(624px, 52%, 999px);
    bottom: 0;
    left: 0;
    transform: translate(40%, 15%) translate3d(0px, 0px, 0px);
}

#preload.slide .preload_shapes#preload_shape4 {
    transform: translate(0%, 600%) translate3d(0px, 0px, 0px);
}

#preload .preload_shapes#preload_shape5 {
    width: clamp(468px, 39%, 749px);
    top: 0;
    left: 0;
    transform: translate(65%, -48%) translate3d(0px, 0px, 0px);
}

#preload.slide .preload_shapes#preload_shape5 {
    transform: translate(0%, 400%) translate3d(0px, 0px, 0px);
}

#preload .preload_shapes#preload_shape6 {
    width: clamp(312px, 26%, 500px);
    bottom: 0;
    right: 0;
    transform: translate(-32%, 16%) translate3d(0px, 0px, 0px);
}

#preload.slide .preload_shapes#preload_shape6 {
    transform: translate(0%, 200%) translate3d(0px, 0px, 0px);
}

#preload .preload_shapes {
    z-index: 1000;
    position: absolute;
    height: auto;
    translate: none;
    rotate: none;
    scale: none;
    transition: transform 1s cubic-bezier(0.8, 0, 0.2, 1);
}