#pre-intro {
    min-height: 100vh;
    height: max-content;
    padding: 5vw;
    padding-top: 8vh;
    position: relative;
    background-color: #060606;
}

#pre-intro_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    /* min-height: 100vh; */
    min-height: 100%;
    z-index: 1;
    mask-size: contain;
}

@keyframes wink {
    0%, 100% {
        transform: scaleX(1) scaleY(1) rotateZ(90deg);
        left: -0.1em;
    }
    50% {
        transform: scaleX(0) scaleY(1.2) rotateZ(90deg);
        left: -0.35em;
    }
}


#pre-intro .pre-intro_lines {
    position: relative;
    display: flex;
    overflow: hidden;
    width: fit-content;
    z-index: 2;
    font-weight: 400;
    
}

#pre-intro #pre-intro_line1 {
    font-size: calc(clamp(2.5rem, 14vw, 30rem) * 0.4);
    margin-bottom: 3vh;
}

#pre-intro #pre-intro_line1:before {
    content: 'HEY';
    transition: all 1s cubic-bezier(0.8, 0, 0.2, 1);
    transform: translate(0%, 100%) translate3d(0px, 0px, 0px);
    
}

#intro.intro-in #pre-intro_line1:before {
    transform: translate(0%, 0%) translate3d(0px, 0px, 0px);
}

#pre-intro #pre-intro_line2 {
    font-size: calc(clamp(2.5rem, 14vw, 30rem) * 0.7);
}

#pre-intro #pre-intro_line2:before {
    content: "I'M KAM";
    transition: all 1s cubic-bezier(0.8, 0, 0.2, 1);
    transform: translate(0%, 100%) translate3d(0px, 0px, 0px);
}

#intro.intro-in #pre-intro_line2:before {
    transform: translate(0%, 0%) translate3d(0px, 0px, 0px);
}

#pre-intro #pre-intro_line5 {
    display: none;
}

#pre-intro #pre-intro_line5:before {
    content: "By Kam";
    transition: all 1s cubic-bezier(0.8, 0, 0.2, 1);
    transform: translate(0%, 100%) translate3d(0px, 0px, 0px);
    transition-delay: 0.3s;
}

#intro.intro-in #pre-intro_line5:before {
    transform: translate(0%, 0%) translate3d(0px, 0px, 0px);
}

#pre-intro #pre-intro_line1 #wink {
    position: relative;
    display: inline-block;
    transition: all 1s cubic-bezier(0.8, 0, 0.2, 1);
    transform: translate(0%, 105%) rotateZ(45deg) translate3d(0px, 0px, 0px);
    transition-delay: 0.8s;
    margin-left: 0.5em;
}

#intro.intro-in #pre-intro_line1 #wink {
    transform: translate(0%, 0%) rotateZ(0) translate3d(0px, 0px, 0px);
}

#pre-intro #pre-intro_line1 #wink::before, #pre-intro #pre-intro_line1 span:after {
    position: absolute;
    content: '.';
    display: block;
    transform-origin: 50% 50%;
    transform-box: fill-box;
    transform: rotateZ(90deg);
}

#pre-intro #pre-intro_line1 #wink::before {
    top: -0.3em;
    left: -0.1em;
    animation-iteration-count: 1;
    animation-timing-function: cubic-bezier(0.8, 0, 0.2, 1);
    animation-delay: 5s;
    animation-name: wink;
    animation-duration: 0.7s;
}

#pre-intro #pre-intro_line1 #wink::after {
    top: 0em;
    left: -0.1em;
}
  

#pre-intro #pre-intro_line3 {
    font-size: clamp(2.5rem, 14vw, 30rem);
    color: var(--second-theme-color);
}

#pre-intro #pre-intro_line3:before {
    content: 'UX DESIGNER';
    transition: all 1s cubic-bezier(0.8, 0, 0.2, 1);
    transform: translate(0%, 100%) translate3d(0px, 0px, 0px);
    transition-delay: 0.1s;
}

#intro.intro-in #pre-intro_line3:before {
    transform: translate(0%, 0%) translate3d(0px, 0px, 0px);
}

#pre-intro #pre-intro_line4 {
    font-size: clamp(2.5rem, 14vw, 30rem);
}

#pre-intro #pre-intro_line4:before {
    content: '+ ENGINEER';
    background: linear-gradient(45deg, var(--main-theme-color), var(--second-theme-color));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all 1s cubic-bezier(0.8, 0, 0.2, 1);
    transform: translate(0%, 100%) translate3d(0px, 0px, 0px);
    transition-delay: 0.2s;
}

#intro.intro-in #pre-intro_line4:before {
    transform: translate(0%, 0%) translate3d(0px, 0px, 0px);
}

#pre-intro .pre-intro_shapes#pre-intro_shape1 {
    transform: translate(0%, -100%) translate3d(0px, 0px, 0px);
}

#intro.pre-out .pre-intro_shapes#pre-intro_shape1 {
    width: clamp(300px, 25%, 480px);
    top: 0;
    right: 0;
    transform: translate(-5%, 5%) translate3d(0px, 0px, 0px);
}

#pre-intro .pre-intro_shapes#pre-intro_shape2 {
    transform: translate(0%, -100%) translate3d(0px, 0px, 0px);
}

#intro.pre-out .pre-intro_shapes#pre-intro_shape2 {
    width: clamp(348px, 29%, 557px);
    top: 0;
    left: 0;
    transform: translate(-3%, 63%) translate3d(0px, 0px, 0px);
}

#pre-intro .pre-intro_shapes#pre-intro_shape3 {
    
    transform: translate(0%, -100%) translate3d(0px, 0px, 0px);
}

#intro.pre-out .pre-intro_shapes#pre-intro_shape3 {
    width: clamp(228px, 19%, 365px);
    top: 0;
    right: 0;
    transform: translate(4%, 152%) translate3d(0px, 0px, 0px);
}

#pre-intro .pre-intro_shapes#pre-intro_shape4 {
    transform: translate(0%, -100%) translate3d(0px, 0px, 0px);
}

#intro.pre-out .pre-intro_shapes#pre-intro_shape4 {
    width: clamp(624px, 52%, 999px);
    bottom: 0;
    left: 0;
    transform: translate(40%, 15%) translate3d(0px, 0px, 0px);
}

#pre-intro .pre-intro_shapes#pre-intro_shape5 {
    transform: translate(0%, -100%) translate3d(0px, 0px, 0px);
}

#intro.pre-out .pre-intro_shapes#pre-intro_shape5 {
    width: clamp(468px, 39%, 749px);
    top: 0;
    left: 0;
    transform: translate(65%, -48%) translate3d(0px, 0px, 0px);
}

#pre-intro .pre-intro_shapes#pre-intro_shape6 {
    transform: translate(0%, -100%) translate3d(0px, 0px, 0px);
}

#intro.pre-out .pre-intro_shapes#pre-intro_shape6 {
    width: clamp(312px, 26%, 500px);
    bottom: 0;
    right: 0;
    transform: translate(-32%, 16%) translate3d(0px, 0px, 0px);
}


#pre-intro .pre-intro_shapes {
    position: absolute;
    height: auto;
    translate: none;
    rotate: none;
    scale: none;
    transition: transform 1s cubic-bezier(0.8, 0, 0.2, 1);
}

#intro.pre-out .pre-intro_shapes {
    transform: translate(0%, 0) scale(1) translate3d(0px, 0px, 0px);
    opacity: 0.5;
    
}

@media only screen and (max-aspect-ratio: 11/10) {

    #pre-intro {
        padding: 8vw;
        padding-top: 10vh;
    }

    #pre-intro #pre-intro_line1 {
        font-size: calc(clamp(2.5rem, 22vw, 25rem) * 0.4);
        margin-bottom: 5vh;
    }

    #pre-intro #pre-intro_line2 {
        display: none;
    }

    #pre-intro #pre-intro_line3 {
        font-size: clamp(2.5rem, 22vw, 25rem);
    }

    #pre-intro #pre-intro_line3:before {
        content: 'UX \A DESIGN';
        white-space: pre;
    }

    #pre-intro #pre-intro_line4 {
        font-size: clamp(2.5rem, 22vw, 25rem);
        margin-bottom: 2vh;
    }

    #pre-intro #pre-intro_line4:before {
        content: '+ CODE';
    }

    #pre-intro #pre-intro_line5 {
        display: flex;
        font-size: calc(clamp(2.5rem, 22vw, 25rem) * 0.5);
    }
}








/* ********************************************************** */

#overlay_menu input {
    display: none;
}
#overlay_menu {
    display: block;
    cursor: pointer;
    position: fixed;
    width: clamp(9em, 8vw, 15em);
    height: clamp(9em, 8vw, 15em);
    /* width: 50vw;
    height: 50vw;
    max-width: 150px;
    max-height: 150px; */
}

#overlay_menu {
    bottom: 0;
    right: 0;
    z-index: 3;
}

@media (min-aspect-ratio: 1/1) {
    #overlay_menu {
    /* width: 50vh;
    height: 50vh; */
  }
}

#overlay_menu path {
  fill: none;
  stroke: var(--main-theme-color);
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
  --length: 24;
  --offset: -38;
  stroke-dasharray: var(--length) var(--total-length);
  stroke-dashoffset: var(--offset);
  transition: all .8s cubic-bezier(.645, .045, .355, 1);
}

#overlay_menu circle {
  /* fill: #fff3; */
  /* opacity: 0; */
  fill: var(--second-theme-color);
  opacity: 0.1;
  
}

#overlay_menu input:checked+svg .line--1,
#overlay_menu input:checked+svg .line--3 {
    --length: 22.627416998;
}

#overlay_menu input:checked+svg .line--2 {
    --length: 0;
}

/* .menu--1 {
  background-color: #3f77e9;
} */

#overlay_menu .line--1,
#overlay_menu .line--3 {
    --total-length: 126.64183044433594;
  }

#overlay_menu .line--2 {
--total-length: 70;
}

#overlay_menu input:checked+svg .line--1,
#overlay_menu input:checked+svg .line--3 {
    --offset: -94.1149185097;
}

#overlay_menu input:checked+svg .line--2 {
    --offset: -50;
}


@media (hover: hover) {
    #overlay_menu:hover circle {
        /* opacity: 1; */
        opacity: 0.2;
    }
}

#overlay_menu.checked circle {
    /* opacity: 1; */
    opacity: 0.2;
}

/* ********************************************************** */













/* ********************************************************** */

#intro-content {
    background-image: linear-gradient(135deg, #2f2a71 0%, #592a71 30%, #ad2443 100%);
    min-height: 100vh;
    position: relative;
    padding: 0 clamp(4rem, 12vw, 20rem);
    display: grid;
    place-content: center;
}

#intro-content p {
    font-size: calc(clamp(2.5rem, 14vw, 30rem) * 0.4);
    letter-spacing: -0.05em;
    margin-bottom: 8rem;
    
}

#intro-content span {
    opacity: 0.1;
}


@media only screen and (max-aspect-ratio: 11/10) {

    #intro-content p {
        font-size: calc(clamp(2.5rem, 22vw, 25rem) * 0.4);
        
    }
}

/* ********************************************************** */









/* .intro:not(.notrans) > .title, */
.intro:not(.notrans) #intro-content,
.intro:not(.notrans) #intro-content > p {
    -webkit-transition-property: opacity, -webkit-transform, background-color;
    transition-property: opacity, transform, background-color;
    -webkit-transition-duration: 1s;
    transition-duration: 1s;
}


#intro:not(.notrans) #pre-intro {
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
}

#intro:not(.notrans) #pre-intro {
    -webkit-transition-timing-function: cubic-bezier(0.7,0,0.3,1);
    transition-timing-function: cubic-bezier(0.7,0,0.3,1);
    -webkit-transition-duration: 1.2s;
    transition-duration: 1.2s;
}

/* #intro .codrops-demos a {
    color: #108576;
} */

#intro #pre-intro {
    /* position: relative; */
    position: absolute;
    z-index: 1500;
    transform: translateY(0) scale(1);
    width: 100%;
    overflow: hidden;
}

/* #intro > .title {
    position: relative;
    top: auto;
    left: auto;
    padding: 6em 1em 2em;
} */

/* #intro #intro-content > div {
    margin-top: 50px;
    color: #b2b2c0;
} */

/* #intro > .title, */
#intro #intro-content {
    /* -webkit-transform: translateY(100%);
    transform: translateY(100%);
    opacity: 0; */
}

/* #intro.intro-effect-push.modify > .title, */
#intro.modify #intro-content {
    /* -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; */
    
}

#intro.modify #pre-intro {
    opacity: 0.5;
    -webkit-transform: translateY(-100%) scale(0.9);
    transform: translateY(-100%) scale(0.9);
    background-color: #592a71;
}

/* Delays */
/* #intro.intro-effect-push.modify:not(.notrans) > .title {
    -webkit-transition-delay: 0.5s;
    transition-delay: 0.5s;
} */

#intro.modify:not(.notrans) .intro-content > p {
    /* -webkit-transition-delay: 0.6s;
    transition-delay: 0.6s; */
}

/* Media Queries */
@media screen and (max-width: 27em) {

    /* #intro #pre-intro .title {
        top: 60px;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    } */
}

/* ********************************************************** */